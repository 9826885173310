@charset "UTF-8";
body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Sora", sans-serif;
  background-color: #181A20; /*#f4fcfa*/
}

@font-face {
  font-family: "Good Times Rg";
  src: url("./fonts/GoodTimesRg-Regular.eot");
  src: url("./fonts/GoodTimesRg-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/GoodTimesRg-Regular.woff2") format("woff2"), url("./fonts/GoodTimesRg-Regular.woff") format("woff"), url("./fonts/GoodTimesRg-Regular.ttf") format("truetype"), url("./fonts/GoodTimesRg-Regular.svg#GoodTimesRg-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.mainimageBox {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.artwork .slick-initialized .slick-slide.slick-current .active {
  transform: scale(1.15);
  transition: all 0.4s ease-in;
}
.artwork .slick-track {
  padding: 60px 0;
}

.slick-prev {
  left: auto;
  top: -50px;
  right: 40px;
  color: #000;
}

.slick-next {
  left: auto;
  top: -50px;
  right: 5px;
  color: #000;
}

.slick-next:before,
.slick-prev:before {
  font-size: 25px;
  line-height: 1;
  opacity: 0.75;
  color: #c1c1c1;
}

::-webkit-scrollbar {
  display: none;
}

.rotate {
  animation: rotate 5s normal linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
.moveTop {
  animation: moveTop 5s normal linear infinite;
}

@keyframes moveTop {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.moveLeft {
  animation: moveLeft 5s normal linear infinite;
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@media (max-width: 767px) {
  img.logoImg {
    max-width: 130px !important;
  }
  .pagenotfound {
    max-width: 400px;
  }
}
.MuiList {
  display: flex;
  align-items: flex-start;
}
.MuiList .h5 {
  line-height: 0px;
}

.MuiTypography-list {
  display: block;
  text-align: center;
  padding-right: 16px;
}

.bid div {
  position: relative;
}

.bid div:after {
  content: "";
  border-right: 1px solid #000;
  height: 80%;
  right: 17px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal_text h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #039be3;
  margin-bottom: 15px;
}
.modal_text p {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #3d3d3d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timing {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #039be3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.timing label {
  font-weight: bold;
  font-size: 75%;
  line-height: 17px;
  color: #000000;
  padding: 0 5px;
}
.timing img {
  margin-left: 5px;
}

.setPrice {
  background: #ffffff;
  border: 1px solid #898989;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 25px;
  height: 150px;
  width: 150px;
  flex-direction: column;
  justify-content: center;
  color: #000000;
  cursor: pointer;
}
.setPrice svg {
  font-size: 35px;
  margin-bottom: 8px;
}
.setPrice img {
  width: 35px;
  margin-bottom: 8px;
}
.setPrice.active {
  border: 1px solid #039be3;
  color: #039be3;
}
.setPrice p {
  color: #898989;
}

.physicalstyle {
  position: absolute;
  background: #f0b514;
  padding: 2px;
  color: #000;
  border-top-left-radius: 5px;
  padding: 0px 10px;
  border-bottom-right-radius: 5px;
}
.physicalstyle .textphysical {
  color: #000;
  font-size: 12px;
}

.displayAlignStart {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createimganimation {
  animation: createimganimation 10s ease-in-out infinite;
  max-width: 100%;
  width: auto;
}

@keyframes createimganimation {
  0% {
    opacity: 1;
    transform: scale(1.1) translateY(-0.75em);
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1.1) translateY(-0.75em);
  }
}
.gradientbutton {
  color: #fff !important;
  border: none;
  padding: 10px 19px 10px 19px !important;
  background: #000;
  border-radius: 10px;
  border: solid 1px transparent !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #7b3e7a, #0c2745);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #050706 inset;
  border-radius: 10px !important;
}

.albumslickbottomslider .slick-initialized .slick-slide.slick-current .active {
  transform: scale(1.15);
  transition: all 0.4s ease-in;
}
.albumslickbottomslider .slick-next:before,
.albumslickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -17px !important;
}
.albumslickbottomslider .slick-track {
  padding: 60px 0;
}
.albumslickbottomslider .slick-prev:before {
  content: "‹";
}
.albumslickbottomslider .slick-next:before {
  content: "›";
}
.albumslickbottomslider .slick-prev {
  left: 45% !important;
  top: 100% !important;
  /* bottom: 0% !important; */
  right: 60px !important;
  color: #fff !important;
  background-color: #000 !important;
  border: 2px solid #323232;
  z-index: 999 !important;
  position: absolute;
}
.albumslickbottomslider .slick-prev:hover {
  background: #000;
  border: solid 2px transparent !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #5248d2, #a92aac) !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #050706 inset !important;
  border-radius: 50px !important;
}
.albumslickbottomslider .slick-prev.slick-disabled {
  opacity: 0.5;
}
.albumslickbottomslider .slick-next {
  position: absolute;
  left: 50%;
  top: 100% !important;
  right: 5px !important;
  color: #fff !important;
  background-color: #000 !important;
  border: 2px solid #323232;
  z-index: 999 !important;
}
.albumslickbottomslider .slick-next:hover {
  background: #000;
  border: solid 2px transparent !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #5248d2, #a92aac) !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #050706 inset !important;
  border-radius: 50px !important;
}
.albumslickbottomslider .slick-next.slick-disabled {
  opacity: 0.5;
}
.albumslickbottomslider .slick-next:before,
.albumslickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  color: #323232;
  margin-top: -17px;
}

.albumslickbottomslider .slick-track {
  padding: 30px 0 60px !important;
}

.topslickslider .slick-next,
.topslickslider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  border-radius: 50%;
  background: 0 0;
}
.topslickslider .slick-prev:before {
  content: "‹";
}
.topslickslider .slick-next:before {
  content: "›";
}
.topslickslider .slick-prev {
  left: auto !important;
  bottom: -20px !important;
  top: -40px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: #000 !important;
  border: 2px solid #323232;
}
.topslickslider .slick-prev:hover {
  background: #000;
  border: solid 2px transparent !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #5248d2, #a92aac) !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #050706 inset !important;
  border-radius: 50px !important;
}
.topslickslider .slick-prev.slick-disabled {
  opacity: 0.5;
}
.topslickslider .slick-next {
  top: -40px !important;
  bottom: -20px !important;
  left: auto !important;
  right: 5px !important;
  color: #fff !important;
  background-color: #000 !important;
  border: 2px solid #323232;
  z-index: 999 !important;
}
.topslickslider .slick-next:hover {
  background: #000;
  border: solid 2px transparent !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #5248d2, #a92aac) !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #050706 inset !important;
  border-radius: 50px !important;
}
.topslickslider .slick-next.slick-disabled {
  opacity: 0.5;
}
.topslickslider .slick-next:before,
.topslickslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -17px !important;
}

.colorBoxgred {
  z-index: 0;
  left: 4%;
  top: -5%;
  right: auto;
  width: 400px;
  bottom: auto;
  filter: blur(100px);
  height: 450px;
  opacity: 0.55;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background: rgba(247, 56, 137, 0.25);
}
@media screen and (max-width: 760px) {
  .colorBoxgred {
    background: rgb(0, 0, 0);
  }
}

.colorBoxgred1 {
  z-index: 0;
  right: 20%;
  top: 13px;
  left: auto;
  width: 400px;
  bottom: auto;
  filter: blur(100px);
  height: 450px;
  opacity: 0.55;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background: rgba(247, 56, 137, 0.25);
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5) !important;
  -webkit-tap-highlight-color: transparent !important;
}

.MuiPaginationItem-page.Mui-selected {
  background: linear-gradient(93.34deg, hsl(230.54, 95.03%, 63.21%) 6.82%, hsl(230.54, 95.03%, 63.21%) 35.9%, hsl(230.54, 95.03%, 63.21%) 68.08%, hsl(230.54, 95.03%, 63.21%) 101.4%) !important;
  border-radius: 6px;
}

.closeButton {
  top: 0 !important;
  color: #fff !important;
  right: 0 !important;
  position: absolute !important;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50% !important;
  width: 40px !important;
  height: 40px !important;
  transform: translateY(-50%);
  cursor: pointer !important;
  color: transparent;
  border: none;
  outline: 0;
  border-radius: 50%;
  background: 0 0;
}

.bgx1 {
  animation: rotating 26s linear infinite;
}

.bgx2 {
  animation: rotating2 19s linear infinite;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.boxSeller {
  background: rgb(134, 134, 134);
  background: radial-gradient(circle, rgb(134, 134, 134) 0%, rgba(60, 60, 60, 0) 60%);
}

.sellerPaginationButton {
  color: #fff !important;
  background-color: #000 !important;
  border: 2px solid #323232 !important;
  cursor: pointer !important;
  z-index: 999 !important;
  padding: 8px !important;
}
.sellerPaginationButton:hover {
  background: #000;
  border: solid 2px transparent !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #5248d2, #a92aac) !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #050706 inset !important;
  border-radius: 50px !important;
}

.dialogBoxHeight {
  min-height: 500px;
}

.cardbuttonbox {
  background: rgba(255, 255, 255, 0.03) !important;
  box-shadow: 0px 0px 0px 3.525px rgba(255, 255, 255, 0.03) !important;
  border-radius: 50px !important;
}

.css-1qzevvg {
  position: relative;
  display: flex;
  height: 40px;
  margin: 1rem auto 0px;
  width: 20%;
  justify-content: space-between;
}

/* Create the border for every card, Paper, and Box*/
.borderGraditant {
  background: linear-gradient(321.29deg, hsl(230.54, 95.03%, 63.21%) -2.72%, hsl(230.54, 95.03%, 63.21%) 23.92%, hsl(230.54, 95.03%, 63.21%) 46.65%, hsl(230.54, 95.03%, 63.21%) 63.7%);
  border-radius: 30px;
  padding: 3px !important;
}

.backgroudBlack {
  padding: 16px;
  position: relative;
  background: #0c0508;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.backgroudBlack1 {
  padding: 16px;
  position: relative;
  background: #fff;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* End */
.css-1qzevvg img {
  height: 100%;
  cursor: pointer;
}

.boxButton {
  position: relative;
  padding: 20px 30px;
  margin: 5px;
  display: inline-block;
  font-size: 30px;
  background: #181A20 !important;
}
.boxButton::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 3px; /* control the border thickness */
  background: rgba(255, 255, 255, 0.13);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}
.boxButton svg {
  color: #fff !important;
}

.boxButton:hover::before {
  background: linear-gradient(163.12deg, hsl(230.54, 95.03%, 63.21%) 16.66%, hsl(230.54, 95.03%, 63.21%) 88.36%), linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03));
}

.bannercarousal {
  width: 100%;
  height: 500px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .bannercarousal {
    height: 260px;
  }
}

@media (max-width: 1600px) {
  .lineBorder {
    left: 39% !important;
  }
}
@media (max-width: 1440px) {
  .lineBorder {
    left: 33% !important;
  }
  .albumslickbottomslider .slick-next {
    left: 51% !important;
  }
}
@media (max-width: 1280px) {
  .lineBorder {
    left: 37% !important;
  }
  .albumslickbottomslider .slick-next {
    left: 52% !important;
  }
}
@media (max-width: 991px) {
  .albumslickbottomslider .slick-next {
    left: 55% !important;
  }
}
@media (max-width: 767px) {
  .logoimageBox {
    width: 130px !important;
  }
  .closeButton {
    padding: 4px !important;
  }
  .slick-dots {
    bottom: 0 !important;
  }
  .albumslickbottomslider .slick-track {
    padding: 30px 0 35px !important;
  }
  .albumslickbottomslider .slick-next {
    left: 57% !important;
  }
  .TabButtonsBox button {
    font-size: 12px;
    margin-right: -5px;
    /* display: flex; */
  }
}
@media (max-width: 599px) {
  .mobile_hide {
    display: none !important;
  }
  .mobile_block {
    display: block !important;
  }
}/*# sourceMappingURL=main.css.map */